<template>
  <div class="w-full h-full">
    <!-- Popup Voucher -->
    <transition name="fade">
      <div
        v-if="show"
        class="opacity-50 fixed inset-0 z-50 bg-black w-full h-full"
      ></div>
    </transition>
    <transition
      enter-active-class="animated bounceIn"
      leave-active-class="animated bounceOut"
    >
      <div
        v-if="show"
        class="overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none justify-center items-center flex z-50"
      >
        <div class="relative w-full md:w-1/3 my-6 mx-4 md:mx-auto">
          <div
            class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[80vh]"
          >
            <div class="flex items-center justify-between p-3 rounded-t">
              <p class="font-desain text-xl text-[#212121] font-semibold">
                Penukaran Voucher Kelas Prakerja
              </p>
              <div
                class="modal-close cursor-pointer hover:bg-gray-200 rounded-sm p-1 duration-500"
                @click="close()"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.6451 4.36708C19.1718 3.89382 18.4073 3.89382 17.934 4.36708L12 10.289L6.06598 4.35495C5.59272 3.88168 4.82821 3.88168 4.35495 4.35495C3.88168 4.82821 3.88168 5.59272 4.35495 6.06598L10.289 12L4.35495 17.934C3.88168 18.4073 3.88168 19.1718 4.35495 19.6451C4.82821 20.1183 5.59272 20.1183 6.06598 19.6451L12 13.711L17.934 19.6451C18.4073 20.1183 19.1718 20.1183 19.6451 19.6451C20.1183 19.1718 20.1183 18.4073 19.6451 17.934L13.711 12L19.6451 6.06598C20.1062 5.60485 20.1062 4.82821 19.6451 4.36708Z"
                    fill="#212121"
                  />
                </svg>
              </div>
            </div>
            <div class="pb-4 flex-auto overflow-x-hiden overflow-y-auto">
              <div
                class="border border-y border-gray-200 px-6 py-4 hover:bg-gray-100 duration-300"
              >
                <div class="grid grid-cols-4 gap-2">
                  <div class="col-span-3 flex items-center">
                    <img
                      :src="classroom?.thumbnail"
                      alt=""
                      class="object-cover rounded-md h-20 w-20"
                    />
                    <div
                      class="ml-2 flex-grow flex flex-col justify-start h-20 relative"
                    >
                      <router-link
                        :to="'/classroom/' + classroom?.code"
                        class="font-solusi text-sm text-[#383838] line-clamp-3 font-semibold hover:text-[#0A72ED] mb-1 overflow-hidden"
                      >
                        {{ classroom?.name }}
                      </router-link>
                      <p
                        v-if="classroom?.mentor_data"
                        class="font-solusi text-xs text-[#757575] font-medium"
                      >
                        Oleh {{ classroom?.mentor_data[0]?.name }}
                      </p>
                    </div>
                  </div>
                  <div class="col-span-1">
                    <p
                      class="font-solusi text-sm text-[#383838] text-right font-semibold"
                    >
                      Rp. {{ classroom?.rupiah_price_formatted }}
                    </p>
                    <p
                      class="font-solusi text-xs text-[#DE1306] text-right font-medium line-through"
                    >
                      Rp. {{ classroom?.normal_price_formatted }}
                    </p>
                  </div>
                </div>
              </div>
              <!-- <img
                src="/assets/images/modal/voucher.svg"
                alt=""
                class="w-36 m-auto"
              /> -->
              <div class="block px-4">
                <p
                  class="font-desain text-[#212121] text-base text-left my-2 font-semibold"
                >
                  Tukarkan Vouchermu!
                </p>
                <form
                  @submit.prevent="redeem"
                  class="w-full flex flex-row justify-center items-center"
                >
                  <input
                    v-focus
                    type="text"
                    required
                    :value="modelValue"
                    @input="udpateValue($event.target.value)"
                    placeholder="Masukkan kode voucher disini"
                    class="flex-grow border border-gray-300 p-2 rounded-md font-solusi text-[#212121] text-sm focus:outline-none focus:ring focus:border-blue-400 font-semibold"
                  />
                  <button
                    type="submit"
                    class="bg-[#00446F] rounded-full px-4 py-2 ml-2 outline-none focus:outline-none flex justify-center items-center"
                    :disabled="loading"
                    :class="loading ? 'cursor-wait' : 'cursor-pointer'"
                  >
                    <div
                      v-if="!loading"
                      class="text-white font-solusi font-semibold text-sm"
                    >
                      Tukarkan
                    </div>
                    <div v-else class="px-3">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="spinner"
                        class="w-5 h-5 animate-spin"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="#ffffff"
                          d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"
                        ></path>
                      </svg>
                    </div>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'RedeemVoucher',
  emits: ['update:modelValue'],
  props: {
    show: Boolean,
    modelValue: String,
    loading: Boolean,
    close: Function,
    redeem: Function,
    classroom: Object,
  },
  data() {
    return {
      code: '' as string,
    }
  },
  methods: {
    udpateValue(value: string) {
      this.$emit('update:modelValue', value)
    },
  },
})
</script>
