
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'RedeemVoucher',
  emits: ['update:modelValue'],
  props: {
    show: Boolean,
    modelValue: String,
    loading: Boolean,
    close: Function,
    redeem: Function,
    classroom: Object,
  },
  data() {
    return {
      code: '' as string,
    }
  },
  methods: {
    udpateValue(value: string) {
      this.$emit('update:modelValue', value)
    },
  },
})
