<template>
  <div class="w-full min-h-[90vh]">
    <div class="my-5 max-w-6xl mx-auto">
      <div class="flex items-start px-3 md:px-6">
        <router-link
          to="/"
          class="font-solusi text-[#2097F6] hover:font-semibold flex items-center text-xs flex-shrink-0"
        >
          Beranda
          <svg
            class="mx-2 w-2.5 h-2.5"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.19918 5.99997L3.2369 2.2958C2.94112 2.01904 2.91929 1.54782 3.18841 1.24363C3.45753 0.939448 3.91574 0.917001 4.21153 1.19376L8.76302 5.44898C9.07899 5.74485 9.07899 6.25516 8.76302 6.55102L4.21153 10.8062C3.91574 11.083 3.45753 11.0606 3.18841 10.7564C2.9193 10.4522 2.94112 9.98096 3.2369 9.7042L7.19918 5.99997Z"
              fill="#2097F7"
            />
          </svg>
        </router-link>
        <div
          class="font-solusi flex items-center text-[#212121] text-xs truncate"
        >
          {{ filteredCategory.name }}
        </div>
      </div>
    </div>

    <div class="my-3 max-w-6xl mx-auto">
      <p class="font-desain text-[#212121] font-semibold text-xl px-3 md:px-6">
        {{ filteredCategory.name }}
      </p>
    </div>
    <div class="max-w-6xl mx-auto md:px-6 md:mt-3">
      <div class="w-full flex md:space-x-8">
        <div
          class="w-[20%] hidden md:flex flex-col bg-[#FFFFFF] shadow-md rounded-md h-fit-content sticky top-20"
        >
          <div class="font-solusi text-[#212121] font-semibold p-3">
            List Kategori
          </div>

          <div :key="highlight.slug" class="flex items-center mb-4 px-3">
            <input
              :id="highlight.slug"
              type="radio"
              name="category"
              :value="highlight.slug"
              class="h-4 w-4 border-gray-300 outline-none focus:outline-none"
              :aria-labelledby="highlight.slug"
              :aria-describedby="highlight.slug"
              :checked="highlight?.slug == category ? true : false"
              @change="changeCategory(highlight.slug)"
            />
            <label
              :for="highlight.slug"
              class="font-solusi text-sm ml-2 block cursor-pointer hover:text-[#009DFF]"
              :class="
                highlight.slug == category
                  ? 'text-[#009DFF] font-semibold'
                  : 'text-[#212121]'
              "
            >
              {{ highlight.name }}
            </label>
          </div>

          <div
            v-for="(cat, i) in categories"
            :key="i"
            class="flex items-center mb-4 px-3"
          >
            <input
              :id="cat.slug"
              type="radio"
              name="category"
              :value="cat.slug"
              class="h-4 w-4 border-gray-300 outline-none focus:outline-none"
              :aria-labelledby="cat.slug"
              :aria-describedby="cat.slug"
              :checked="cat.slug == category ? true : false"
              @change="changeCategory(cat?.slug)"
            />
            <label
              :for="cat.slug"
              class="font-solusi text-sm ml-2 block cursor-pointer hover:text-[#009DFF]"
              :class="
                cat.slug == category
                  ? 'text-[#009DFF] font-semibold'
                  : 'text-[#212121]'
              "
            >
              {{ cat.name }}
            </label>
          </div>
        </div>
        <div
          v-show="filteredCategory?.classrooms?.length == 0"
          class="w-full md:w-[80%] bg-[#FFFFFF] shadow-md rounded-md p-4 space-y-4 h-fit-content flex flex-col justify-center items-center py-32 md:py-24"
        >
          <img
            src="/assets/images/mentor/empty-state.svg"
            class="w-3/4 md:w-1/3"
            alt=""
          />
          <p class="font-solusi text-[#383838] font-semibold mt-3 text-center">
            Kelas untuk kategori {{ filteredCategory.name }} belum ada.
          </p>
        </div>
        <div
          v-show="filteredCategory?.classrooms?.length > 0"
          class="w-full md:w-[80%] bg-[#FFFFFF] shadow-md rounded-md p-4 space-y-4 h-fit-content"
        >
          <div
            v-for="(classroom, i) in filteredCategory?.classrooms"
            :key="i"
            class="p-2 rounded-lg bg-white duration-300 w-full shadow hover:shadow-md cursor-pointer"
            @click="beforeAddToCart(classroom)"
          >
            <div
              class="flex flex-row justify-between h-32 md:h-[9.5rem] relative"
            >
              <img
                :src="classroom.thumbnail"
                alt=""
                class="rounded-lg flex-auto object-cover md:object-fill w-32 md:w-72 h-32 md:h-[9.5rem] object-center cursor-pointer flex-grow-0 flex-shrink-0"
              />
              <div
                class="flex-auto flex flex-col flex-grow ml-3 justify-between box-border place-content-end"
              >
                <div class="flex flex-col flex-grow relative box-border">
                  <div
                    v-tooltip="{
                      content: classroom.name,
                    }"
                    class=" text-sm font-solusi font-semibold text-[#383838] text-left line-clamp-2 overflow-hidden mb-2 cursor-pointer"
                  >
                    {{ classroom.name }}
                  </div>
                  <div
                    v-if="classroom?.mentor_data"
                    class="flex items-center w-full mb-2"
                  >
                    <img
                      :src="classroom?.mentor_data[0]?.photo"
                      alt=""
                      class="rounded-full h-10 w-10"
                    />
                    <div class="flex flex-col w-full ml-2 truncate">
                      <p
                        class="font-solusi text-xs text-[#212121] font-semibold text-left mb-1 truncate"
                      >
                        {{ classroom?.mentor_data[0]?.name }}
                      </p>
                      <p
                        class="font-solusi text-2xs text-[#383838] text-left truncate"
                      >
                        {{ classroom?.mentor_data[0]?.attribution }}
                      </p>
                    </div>
                  </div>
                  <div class="flex items-center">
                    <div v-for="i in 5" :key="i" class="">
                      <svg
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        class="w-4 h-4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.0489 2.92705C17.3483 2.00574 18.6517 2.00574 18.9511 2.92705L21.8167 11.7467C21.9506 12.1587 22.3346 12.4377 22.7678 12.4377H32.0413C33.0101 12.4377 33.4128 13.6773 32.6291 14.2467L25.1267 19.6976C24.7762 19.9522 24.6295 20.4036 24.7634 20.8156L27.6291 29.6353C27.9284 30.5566 26.874 31.3227 26.0902 30.7533L18.5878 25.3024C18.2373 25.0478 17.7627 25.0478 17.4122 25.3024L9.90976 30.7533C9.12605 31.3227 8.07157 30.5566 8.37092 29.6353L11.2366 20.8156C11.3705 20.4036 11.2238 19.9522 10.8733 19.6976L3.37088 14.2467C2.58717 13.6773 2.98994 12.4377 3.95867 12.4377H13.2322C13.6654 12.4377 14.0494 12.1587 14.1833 11.7467L17.0489 2.92705Z"
                          fill="#FFC200"
                        />
                      </svg>
                    </div>
                    <div class="ml-1 flex flex-grow items-center">
                      <span
                        class="font-solusi text-xs font-semibold text-[#212121] mx-1"
                        >{{ classroom?.rating }}</span
                      >
                      <span
                        class="font-solusi text-xs font-normal text-[#9E9E9E]"
                        >({{ classroom?.subscribers }} Rating)</span
                      >
                    </div>
                  </div>
                </div>
                <div class="flex justify-between items-center">
                  <div class="hidden md:flex items-center">
                    <div class="font-desain text-xl font-semibold mr-1">
                      Rp. {{ classroom?.rupiah_price_formatted }}
                    </div>
                    <div
                      v-if="classroom?.normal_price > 0"
                      class="font-solusi text-[#DE1306] text-sm font-normal line-through"
                    >
                      Rp. {{ classroom?.normal_price_formatted }}
                    </div>
                  </div>
                  <div
                    v-if="classroom?.is_purchased"
                    class="hidden md:block rounded-full text-white font-solusi text-xs text-center py-2 font-semibold mt-2 w-44 bg-[#00446F]"
                  >
                    Lanjutkan
                  </div>
                  <button
                    v-else
                    class="hidden md:block rounded-full text-white font-solusi text-xs text-center py-2 font-semibold mt-2 w-44"
                    :class="
                      classroom?.must_voucher
                        ? 'bg-[#FB8500]'
                        : checkWorkshopClass(classroom?.code)
                        ? 'bg-[#219EBC]'
                        : isPreOrder(classroom?.started_at)
                        ? 'bg-[#FB8500]'
                        : 'bg-[#219EBC]'
                    "
                  >
                    {{
                      classroom?.must_voucher
                        ? 'Tukarkan Voucher'
                        : checkWorkshopClass(classroom?.code)
                        ? 'Beli Kelas'
                        : isPreOrder(classroom?.started_at)
                        ? 'Pre-Order Sekarang'
                        : 'Beli Kelas'
                    }}
                  </button>
                </div>
              </div>
            </div>
            <div
              class="block md:hidden border-b-2 border-[#F5F6F8] w-full my-2"
            ></div>
            <div class="flex md:hidden items-start justify-between">
              <div class="flex items-center">
                <p class="font-desain text-base font-semibold mr-1">
                  Rp. {{ classroom?.rupiah_price_formatted }}
                  <span
                    v-if="classroom?.normal_price > 0"
                    class="font-solusi text-[#DE1306] text-xs font-normal line-through"
                  >
                    Rp. {{ classroom?.normal_price_formatted }}
                  </span>
                </p>
              </div>
              <button
                class="rounded-full text-white font-solusi text-xs text-center py-2 font-semibold w-40"
                :class="
                  classroom?.must_voucher
                    ? 'bg-[#FB8500]'
                    : checkWorkshopClass(classroom?.code)
                    ? 'bg-[#219EBC]'
                    : isPreOrder(classroom?.started_at)
                    ? 'bg-[#FB8500]'
                    : 'bg-[#219EBC]'
                "
              >
                {{
                  classroom?.must_voucher
                    ? 'Tukarkan Voucher'
                    : checkWorkshopClass(classroom?.code)
                    ? 'Beli Kelas'
                    : isPreOrder(classroom?.started_at)
                    ? 'Pre-Order Sekarang'
                    : 'Beli Kelas'
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Popup Add To Cart -->
    <transition name="fade">
      <div
        v-if="showModalAddToCart"
        class="opacity-50 fixed inset-0 z-50 bg-black w-full h-full"
      ></div>
    </transition>
    <transition
      enter-active-class="animated zoomIn"
      leave-active-class="animated zoomOut"
    >
      <div
        v-if="showModalAddToCart"
        class="overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none justify-center items-center flex z-51"
      >
        <div class="relative w-full md:w-1/3 my-auto md:my-6 mx-2 md:mx-auto">
          <div
            v-click-away="closeModalAddToCart"
            class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none divide-y divide-gray-200 max-h-[65vh]"
          >
            <div class="flex items-center justify-between px-5 py-3 rounded-t">
              <div class="flex items-center">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  class="mr-2"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="12" cy="12" r="12" fill="#2FA742" />
                  <path
                    d="M9.27366 17.9117C9.54836 18.1935 9.93059 18.3532 10.3301 18.3532C10.3339 18.3532 10.338 18.3532 10.3423 18.3532C10.7464 18.3498 11.1307 18.1838 11.4033 17.8943L19.3842 9.41787C19.9253 8.84321 19.8833 7.95201 19.2909 7.42732C18.6983 6.903 17.7792 6.94339 17.2381 7.51786L10.312 14.8737L8.15605 12.6598C7.60471 12.0939 6.68489 12.0687 6.10178 12.6028C5.51827 13.1371 5.49231 14.0287 6.04308 14.5945L9.27366 17.9117Z"
                    fill="white"
                  />
                </svg>
                <p class="font-desain text-lg text-[#212121] font-semibold">
                  Berhasil ditambahkan ke keranjang
                </p>
              </div>
              <div
                class="modal-close cursor-pointer hover:bg-gray-200 rounded-sm p-1 duration-500"
                @click="closeModalAddToCart()"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.6451 4.36708C19.1718 3.89382 18.4073 3.89382 17.934 4.36708L12 10.289L6.06598 4.35495C5.59272 3.88168 4.82821 3.88168 4.35495 4.35495C3.88168 4.82821 3.88168 5.59272 4.35495 6.06598L10.289 12L4.35495 17.934C3.88168 18.4073 3.88168 19.1718 4.35495 19.6451C4.82821 20.1183 5.59272 20.1183 6.06598 19.6451L12 13.711L17.934 19.6451C18.4073 20.1183 19.1718 20.1183 19.6451 19.6451C20.1183 19.1718 20.1183 18.4073 19.6451 17.934L13.711 12L19.6451 6.06598C20.1062 5.60485 20.1062 4.82821 19.6451 4.36708Z"
                    fill="#212121"
                  />
                </svg>
              </div>
            </div>
            <div class="overflow-y-auto divide-y divide-gray-200 scroll-custom">
              <div
                v-for="(cart, i) in getCartData"
                :key="i"
                class="px-6 py-4 hover:bg-gray-100 duration-300"
              >
                <div class="grid grid-cols-4 gap-2">
                  <div class="col-span-3 flex items-center">
                    <img
                      :src="cart.thumbnail"
                      alt=""
                      class="object-cover rounded-md h-20 w-20"
                    />
                    <div
                      class="ml-2 flex-grow flex flex-col justify-start h-20 relative"
                    >
                      <router-link
                        :to="'/classroom/' + cart.code"
                        class="font-solusi text-sm text-[#383838] line-clamp-3 font-semibold hover:text-[#0A72ED] mb-1 overflow-hidden"
                      >
                        {{ cart.name }}
                      </router-link>
                      <p
                        v-if="cart.mentor_data"
                        class="font-solusi text-xs text-[#757575] font-medium"
                      >
                        Oleh {{ cart.mentor_data[0].name }}
                      </p>
                    </div>
                  </div>
                  <div class="col-span-1">
                    <p
                      class="font-solusi text-sm text-[#383838] text-right font-semibold"
                    >
                      Rp. {{ cart.price_formatted }}
                    </p>
                    <p
                      class="font-solusi text-xs text-[#DE1306] text-right font-medium line-through"
                    >
                      Rp. {{ cart.normal_price_formatted }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-col">
              <div class="flex justify-between items-center px-5 py-3">
                <p class="font-desain text-lg text-[#383838] font-semibold">
                  Total Harga
                </p>
                <p class="font-desain text-lg textblack-60 font-semibold">
                  Rp. {{ cartTotalPriceFormat() }}
                </p>
              </div>
              <div class="grid grid-cols-2 gap-6 px-5 mb-6">
                <router-link
                  to="/cart"
                  class="text-center bg-white rounded-full border-2 border-[#00446F] text-[#00446F] py-2 font-solusi font-semibold"
                >
                  Lihat Keranjang
                </router-link>
                <router-link
                  to="/cart?next=buy-now"
                  class="text-center bg-[#00446F] rounded-full border-2 border-[#00446F] text-white py-2 font-solusi font-semibold"
                >
                  Lanjut Bayar
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <Loading :show="showLoading" />

    <!-- Popup Voucher -->
    <transition name="fade">
      <div
        v-if="showModalVoucher"
        class="opacity-50 fixed inset-0 z-51 bg-black w-full h-full"
      ></div>
    </transition>
    <transition
      enter-active-class="animated bounceIn"
      leave-active-class="animated bounceOut"
    >
      <div
        v-if="showModalVoucher"
        class="overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none justify-center items-center flex z-51"
      >
        <div class="relative w-full md:w-1/3 my-6 mx-4 md:mx-auto">
          <div
            v-click-away="closeModalVoucher"
            class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[80vh]"
          >
            <div class="flex items-start justify-end px-3 pt-3 rounded-t">
              <div
                class="modal-close cursor-pointer hover:bg-gray-200 rounded-sm p-1 duration-500"
                @click="closeModalVoucher()"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.6451 4.36708C19.1718 3.89382 18.4073 3.89382 17.934 4.36708L12 10.289L6.06598 4.35495C5.59272 3.88168 4.82821 3.88168 4.35495 4.35495C3.88168 4.82821 3.88168 5.59272 4.35495 6.06598L10.289 12L4.35495 17.934C3.88168 18.4073 3.88168 19.1718 4.35495 19.6451C4.82821 20.1183 5.59272 20.1183 6.06598 19.6451L12 13.711L17.934 19.6451C18.4073 20.1183 19.1718 20.1183 19.6451 19.6451C20.1183 19.1718 20.1183 18.4073 19.6451 17.934L13.711 12L19.6451 6.06598C20.1062 5.60485 20.1062 4.82821 19.6451 4.36708Z"
                    fill="#212121"
                  />
                </svg>
              </div>
            </div>
            <div class="pb-6 px-6 flex-auto overflow-x-hiden overflow-y-auto">
              <img
                :src="
                  redeemStatus
                    ? '/assets/images/modal/voucher-success.svg'
                    : '/assets/images/modal/voucher-failed.svg'
                "
                alt=""
                class="w-36 md:w-40 m-auto"
              />
              <p
                class="font-desain text-[#212121] text-lg text-center my-2 font-semibold"
              >
                {{ redeemStatus ? 'Berhasil' : 'Gagal' }} Menukarkan Voucher
              </p>
              <p
                v-if="redeemStatus"
                class="font-solusi text-[#212121] text-sm text-center mb-4"
              >
                {{ voucherDetail }}
              </p>
              <p
                v-else
                class="font-solusi text-[#212121] text-sm text-center mb-4"
              >
                Sayang sekali, kode voucher yang kamu masukkan tidak valid,
                tukarkan voucher lainnya!
              </p>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <RedeemVoucher
      :show="showRedeemVoucher"
      v-model="codeRedeemVoucher"
      :loading="loadingRedeemVoucher"
      :close="closeRedeemVoucher"
      :redeem="submitRedeemVoucher"
      :classroom="selectedClassroom"
      @update:code="onChangeCode"
    />
  </div>
</template>

<script lang="ts">
import { Cart, Classroom } from '@/dto'
import ClassroomModel from '@/models/ClassroomModel'
import CartModel from '@/models/CartModel'
import Auth from '@/utils/Auth'
import moment from 'moment'
import Loading from '@/main/components/Loading.vue'
import VoucherClassroomModel from '@/models/VoucherClassroomModel'
import RedeemVoucher from '@/main/components/Cards/RedeemVoucher.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Category',
  setup() {
    return {
      Auth,
    }
  },
  components: {
    Loading,
    RedeemVoucher,
  },
  data() {
    return {
      category: this.$route.query.q || null,
      categories: [] as any,
      highlight: [] as any,
      filteredCategory: {} as any,
      loadingCategory: false,
      carts: [] as Cart[],
      showModalAddToCart: false,
      showLoading: false as boolean,

      showModalVoucher: false,
      redeemStatus: false,
      loadingVoucher: false,
      voucherDetail: '' as any,

      showRedeemVoucher: false as boolean,
      codeRedeemVoucher: '' as string,
      loadingRedeemVoucher: false as boolean,
      selectedClassroom: {} as Classroom,
    }
  },
  async mounted() {
    this.loadingCategory = true
    await this.getHighlight()
    await this.$store.dispatch('homeStore/fetchCategories').then(() => {
      // for pagination
      this.categories = this.$store.getters['homeStore/getCategories'].filter(
        (e: any) => e.id > 0 && e.slug !== 'master'
      )
    })
    await this.changeCategory(this.category)
    this.loadingCategory = false
  },
  computed: {
    getLoadingCategory(): boolean {
      return this.$store.getters['homeStore/getLoadingCategory']
    },
    getCategoriesData(): Array<any> {
      const category = this.$store.getters['homeStore/getCategories'].filter(
        (e: any) => e.id > 0 && e.slug !== 'master'
      )
      return category
    },
    getCartLoading(): boolean {
      return this.$store.getters['cartStore/getCartLoading']
    },
    getCartData(): Array<any> {
      return this.$store.getters['cartStore/getCartData']
    },
  },
  methods: {
    redirectClassroom(code: string, slug: string) {
      if (slug != '') {
        window.location.href = slug
      } else {
        this.$router.push('/classroom/' + code + '/')
      }
    },
    async getHighlight() {
      if (Auth.isLoggedIn()) {
        await ClassroomModel.getHighlightPrivate()
          .then(response => {
            if (response.success) {
              this.highlight = response.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        await ClassroomModel.getHighlight()
          .then(response => {
            if (response.success) {
              this.highlight = response.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
    changeCategory(q: any) {
      this.category = q
      if (q == null) {
        this.category = this.highlight.slug
        this.$router.push('/category?q=' + this.category)
        this.filteredCategory = this.highlight
        // console.log(this.highlight)
      } else {
        this.$router.push('/category?q=' + this.category)
        if (q == this.highlight.slug) {
          this.filteredCategory = this.highlight
        } else {
          const filter = this.categories.filter((el: any) => {
            if (el.slug == this.category) {
              return el
            }
          })
          if (filter.length > 0) {
            this.filteredCategory = filter[0]
          }
        }
      }
      // console.log(this.category)

      // console.log('filter', this.filteredCategory)
    },

    timeZone(date: string) {
      const dt = new Date(date)
      const offset = new Date().getTimezoneOffset()
      const timeZoneOffset = offset / 60

      switch (timeZoneOffset) {
        case -8:
          dt.setHours(dt.getHours() + 1)
          break
        case -9:
          dt.setHours(dt.getHours() + 2)
          break
        default:
          break
      }
      return moment(dt).format('YYYY-MM-DD HH:mm:ss')
    },

    isPreOrder(date: string) {
      const now = moment().format('YYYY-MM-DD HH:mm:ss')
      if (now > this.timeZone(date)) {
        return false
      } else {
        return true
      }
    },

    async getCart() {
      await this.$store.dispatch('cartStore/fetchCart')
    },

    async addToCart(code: string) {
      if (Auth.isLoggedIn()) {
        this.showLoading = true
        await CartModel.addSingle(code)
          .then(async res => {
            if (res.success) {
              await this.getCart()
              this.showModalAddToCart = true
            }
            this.showLoading = false
          })
          .catch(err => {
            this.showLoading = false
            console.log(err)
          })
      } else {
        this.$router.push('/register?redirect=/classroom/' + code)
      }
    },

    closeModalAddToCart() {
      this.showModalAddToCart = false
    },

    cartTotalPriceFormat() {
      let sum = 0
      this.getCartData.forEach((el: any) => {
        sum += el.price
      })

      return sum.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    },

    beforeAddToCart(classroom: any) {
      if (classroom.is_purchased) {
        this.$router.push('/classroom/' + classroom?.code + '/activity')
      } else {
        if (classroom?.must_voucher) {
          this.selectedClassroom = classroom
          this.openRedeemVoucher()
        } else {
          this.addToCart(classroom.code)
        }
      }
    },

    checkWorkshopClass(code: string) {
      if (code.charAt(0) == 'W' || code.charAt(0) == 'M') {
        return true
      } else {
        return false
      }
    },

    closeModalVoucher() {
      this.showModalVoucher = false
      this.codeRedeemVoucher = ''
      this.voucherDetail = null
      if (this.redeemStatus) {
        this.$router.push('/')
      }
    },

    closeRedeemVoucher() {
      this.showRedeemVoucher = false
    },

    openRedeemVoucher() {
      this.showRedeemVoucher = true
      this.codeRedeemVoucher = ''
      this.loadingRedeemVoucher = false
    },

    async submitRedeemVoucher() {
      if (Auth.isLoggedIn()) {
        if (this.codeRedeemVoucher) {
          this.loadingRedeemVoucher = true
          try {
            const { message, success } = await VoucherClassroomModel.redeem(
              this.codeRedeemVoucher
            )
            if (success) {
              this.closeRedeemVoucher()
              this.voucherDetail = message
              this.loadingRedeemVoucher = false
              this.redeemStatus = true
              this.showModalVoucher = true
            } else {
              this.loadingRedeemVoucher = false
              this.redeemStatus = false
              this.showModalVoucher = true
            }
          } catch (err) {
            const error: any = (err as any).response.data.errors[0].message
            console.log(error)
            this.loadingRedeemVoucher = false
            this.redeemStatus = false
            this.showModalVoucher = true
          }
        }
      } else {
        this.$router.push('/register?q=daftar')
      }
    },
  },
})
</script>

<style scoped></style>
