
import { Cart, Classroom } from '@/dto'
import ClassroomModel from '@/models/ClassroomModel'
import CartModel from '@/models/CartModel'
import Auth from '@/utils/Auth'
import moment from 'moment'
import Loading from '@/main/components/Loading.vue'
import VoucherClassroomModel from '@/models/VoucherClassroomModel'
import RedeemVoucher from '@/main/components/Cards/RedeemVoucher.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Category',
  setup() {
    return {
      Auth,
    }
  },
  components: {
    Loading,
    RedeemVoucher,
  },
  data() {
    return {
      category: this.$route.query.q || null,
      categories: [] as any,
      highlight: [] as any,
      filteredCategory: {} as any,
      loadingCategory: false,
      carts: [] as Cart[],
      showModalAddToCart: false,
      showLoading: false as boolean,

      showModalVoucher: false,
      redeemStatus: false,
      loadingVoucher: false,
      voucherDetail: '' as any,

      showRedeemVoucher: false as boolean,
      codeRedeemVoucher: '' as string,
      loadingRedeemVoucher: false as boolean,
      selectedClassroom: {} as Classroom,
    }
  },
  async mounted() {
    this.loadingCategory = true
    await this.getHighlight()
    await this.$store.dispatch('homeStore/fetchCategories').then(() => {
      // for pagination
      this.categories = this.$store.getters['homeStore/getCategories'].filter(
        (e: any) => e.id > 0 && e.slug !== 'master'
      )
    })
    await this.changeCategory(this.category)
    this.loadingCategory = false
  },
  computed: {
    getLoadingCategory(): boolean {
      return this.$store.getters['homeStore/getLoadingCategory']
    },
    getCategoriesData(): Array<any> {
      const category = this.$store.getters['homeStore/getCategories'].filter(
        (e: any) => e.id > 0 && e.slug !== 'master'
      )
      return category
    },
    getCartLoading(): boolean {
      return this.$store.getters['cartStore/getCartLoading']
    },
    getCartData(): Array<any> {
      return this.$store.getters['cartStore/getCartData']
    },
  },
  methods: {
    redirectClassroom(code: string, slug: string) {
      if (slug != '') {
        window.location.href = slug
      } else {
        this.$router.push('/classroom/' + code + '/')
      }
    },
    async getHighlight() {
      if (Auth.isLoggedIn()) {
        await ClassroomModel.getHighlightPrivate()
          .then(response => {
            if (response.success) {
              this.highlight = response.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        await ClassroomModel.getHighlight()
          .then(response => {
            if (response.success) {
              this.highlight = response.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
    changeCategory(q: any) {
      this.category = q
      if (q == null) {
        this.category = this.highlight.slug
        this.$router.push('/category?q=' + this.category)
        this.filteredCategory = this.highlight
        // console.log(this.highlight)
      } else {
        this.$router.push('/category?q=' + this.category)
        if (q == this.highlight.slug) {
          this.filteredCategory = this.highlight
        } else {
          const filter = this.categories.filter((el: any) => {
            if (el.slug == this.category) {
              return el
            }
          })
          if (filter.length > 0) {
            this.filteredCategory = filter[0]
          }
        }
      }
      // console.log(this.category)

      // console.log('filter', this.filteredCategory)
    },

    timeZone(date: string) {
      const dt = new Date(date)
      const offset = new Date().getTimezoneOffset()
      const timeZoneOffset = offset / 60

      switch (timeZoneOffset) {
        case -8:
          dt.setHours(dt.getHours() + 1)
          break
        case -9:
          dt.setHours(dt.getHours() + 2)
          break
        default:
          break
      }
      return moment(dt).format('YYYY-MM-DD HH:mm:ss')
    },

    isPreOrder(date: string) {
      const now = moment().format('YYYY-MM-DD HH:mm:ss')
      if (now > this.timeZone(date)) {
        return false
      } else {
        return true
      }
    },

    async getCart() {
      await this.$store.dispatch('cartStore/fetchCart')
    },

    async addToCart(code: string) {
      if (Auth.isLoggedIn()) {
        this.showLoading = true
        await CartModel.addSingle(code)
          .then(async res => {
            if (res.success) {
              await this.getCart()
              this.showModalAddToCart = true
            }
            this.showLoading = false
          })
          .catch(err => {
            this.showLoading = false
            console.log(err)
          })
      } else {
        this.$router.push('/register?redirect=/classroom/' + code)
      }
    },

    closeModalAddToCart() {
      this.showModalAddToCart = false
    },

    cartTotalPriceFormat() {
      let sum = 0
      this.getCartData.forEach((el: any) => {
        sum += el.price
      })

      return sum.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    },

    beforeAddToCart(classroom: any) {
      if (classroom.is_purchased) {
        this.$router.push('/classroom/' + classroom?.code + '/activity')
      } else {
        if (classroom?.must_voucher) {
          this.selectedClassroom = classroom
          this.openRedeemVoucher()
        } else {
          this.addToCart(classroom.code)
        }
      }
    },

    checkWorkshopClass(code: string) {
      if (code.charAt(0) == 'W' || code.charAt(0) == 'M') {
        return true
      } else {
        return false
      }
    },

    closeModalVoucher() {
      this.showModalVoucher = false
      this.codeRedeemVoucher = ''
      this.voucherDetail = null
      if (this.redeemStatus) {
        this.$router.push('/')
      }
    },

    closeRedeemVoucher() {
      this.showRedeemVoucher = false
    },

    openRedeemVoucher() {
      this.showRedeemVoucher = true
      this.codeRedeemVoucher = ''
      this.loadingRedeemVoucher = false
    },

    async submitRedeemVoucher() {
      if (Auth.isLoggedIn()) {
        if (this.codeRedeemVoucher) {
          this.loadingRedeemVoucher = true
          try {
            const { message, success } = await VoucherClassroomModel.redeem(
              this.codeRedeemVoucher
            )
            if (success) {
              this.closeRedeemVoucher()
              this.voucherDetail = message
              this.loadingRedeemVoucher = false
              this.redeemStatus = true
              this.showModalVoucher = true
            } else {
              this.loadingRedeemVoucher = false
              this.redeemStatus = false
              this.showModalVoucher = true
            }
          } catch (err) {
            const error: any = (err as any).response.data.errors[0].message
            console.log(error)
            this.loadingRedeemVoucher = false
            this.redeemStatus = false
            this.showModalVoucher = true
          }
        }
      } else {
        this.$router.push('/register?q=daftar')
      }
    },
  },
})
